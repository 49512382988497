@charset "UTF-8";
/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Reset and Variables
# Helpers
# Bootstrap
# Base
# Parts
# Components / Templates
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Reset and Variables
--------------------------------------------------------------*/
/*--------------------------------------------------------------
## Reset
--------------------------------------------------------------*/
body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, fieldset, input, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0;
}

html {
  -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section {
  display: block;
}

audio, canvas, video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

audio:not([controls]) {
  display: none;
}

[hidden] {
  display: none;
}

/*--------------------------------------------------------------
## Base Reset
--------------------------------------------------------------*/
html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

html, button, input, select, textarea {
  font-family: sans-serif;
  color: #222;
}

body {
  margin: 0;
  font-size: 1em;
  line-height: 1.4;
  -moz-osx-font-smoothing: grayscale;
}

::-moz-selection {
  background: #000;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: #000;
  color: #fff;
  text-shadow: none;
}

/*--------------------------------------------------------------
## Links
--------------------------------------------------------------*/
a {
  text-decoration: none;
  color: #000;
}

a:focus, a:hover, a:active {
  outline: none;
}

/*--------------------------------------------------------------
## Typography
--------------------------------------------------------------*/
abbr[title] {
  border-bottom: 1px dotted;
}

b, strong {
  font-weight: bold;
}

blockquote {
  margin: 0;
}

dfn {
  font-style: italic;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

ins {
  background: #ff9;
  color: #000;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: monospace, serif;
  _font-family: 'courier new', monospace;
  font-size: 1em;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

q {
  quotes: none;
}

q::before, q::after {
  content: "";
  content: none;
}

small {
  font-size: 85%;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/*--------------------------------------------------------------
## Lists
--------------------------------------------------------------*/
nav ul,
nav ol,
ul,
li {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

/*--------------------------------------------------------------
## Embedded Content
--------------------------------------------------------------*/
img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle;
}

svg:not(:root) {
  overflow: hidden;
}

/*--------------------------------------------------------------
## Forms
--------------------------------------------------------------*/
figure {
  margin: 0;
}

form {
  margin: 0;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

label {
  cursor: pointer;
}

legend {
  border: 0;
  *margin-left: -7px;
  padding: 0;
  white-space: normal;
}

button, input, select, textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
}

button, input {
  line-height: normal;
}

button, input[type="button"], input[type="reset"], input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
  *overflow: visible;
}

button[disabled], input[disabled] {
  cursor: default;
}

input, textarea {
  -webkit-box-shadow: none;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  *width: 13px;
  *height: 13px;
}

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

input:invalid, textarea:invalid {
  background-color: #f0dddd;
}

input, textarea, select {
  outline: none;
}

/*--------------------------------------------------------------
## Tables
--------------------------------------------------------------*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}

td {
  vertical-align: top;
}

/*--------------------------------------------------------------
## Chrome Frame Prompt
--------------------------------------------------------------*/
.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: black;
  padding: 0.2em 0;
}

/*--------------------------------------------------------------
## Fonts
--------------------------------------------------------------*/
@font-face {
  font-family: 'Gilroy';
  src: url("assets/fonts/Gilroy-Light.woff2") format("woff2"), url("assets/fonts/Gilroy-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url("assets/fonts/Gilroy-Bold.woff2") format("woff2"), url("assets/fonts/Gilroy-Bold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url("assets/fonts/Gilroy-Heavy.woff2") format("woff2"), url("assets/fonts/Gilroy-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url("assets/fonts/Gilroy-Regular.woff2") format("woff2"), url("assets/fonts/Gilroy-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url("assets/fonts/Gilroy-Medium.woff2") format("woff2"), url("assets/fonts/Gilroy-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

/*--------------------------------------------------------------
## Variables
--------------------------------------------------------------*/
@font-face {
  font-family: "fonticons";
  src: url("assets/icons/fonticons.woff2") format("woff2"), url("assets/icons/fonticons.woff") format("woff"), url("assets/icons/fonticons.ttf") format("truetype");
}

.font-arrow-down:before, .font-facebook:before, .font-insta:before, .font-linkedin:before, .font-medium:before, .font-paperclip:before, .font-quotes:before, .font-twitter:before {
  font-family: "fonticons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
}

.font-arrow-down:before {
  content: "";
}

.font-facebook:before {
  content: "";
}

.font-insta:before {
  content: "";
}

.font-linkedin:before {
  content: "";
}

.font-medium:before {
  content: "";
}

.font-paperclip:before {
  content: "";
}

.font-quotes:before {
  content: "";
}

.font-twitter:before {
  content: "";
}

/*--------------------------------------------------------------
# Helpers
--------------------------------------------------------------*/
/*--------------------------------------------------------------
## Mixins
--------------------------------------------------------------*/
/*
	Example:
	-------------------------------------------------------------------------------------------------
	@include mq($phone) 			 	@media screen and (max-width: 640px)
	-------------------------------------------------------------------------------------------------
	@include mq(991) 			 	 	@media screen and (max-width: 991px)
	-------------------------------------------------------------------------------------------------
	@include mq($phone + 1, min) 	 	@media screen and (min-width: 641px)
	-------------------------------------------------------------------------------------------------
	@include mq(992, min) 			 	@media screen and (min-width: 992px)
	-------------------------------------------------------------------------------------------------
	@include mq(400, min, height) 	 	@media screen and (min-height: 400px)
	-------------------------------------------------------------------------------------------------
	@include mq(992) {				 	@media screen and (max-width: 992px) and (min-width: 767px)
		@include mq(767, min) {

		}
	}
	-------------------------------------------------------------------------------------------------
 */
/*--------------------------------------------------------------
## Helpers
--------------------------------------------------------------*/
.centered {
  text-align: center;
}

.sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  word-wrap: normal !important;
  /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}

.sr-only:focus {
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  display: block;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */
}

/*--------------------------------------------------------------
## Print
--------------------------------------------------------------*/
@media print {
  body {
    background: #fff;
    color: #000;
    margin: 10px 0;
    padding: 0;
    text-align: left;
  }
  a:link, a:visited {
    background: #fff;
    color: #000;
    text-decoration: underline;
  }
  a:not([href="javascript:;"])::after {
    content: " [" attr(href) "] ";
  }
  h1, h2, h3, h4, h5, h6, span {
    background: none;
    color: #000 !important;
  }
  .temp,
  .site-header,
  .site-footer,
  .banner,
  .entry-footer {
    display: none;
  }
  /*--------------------------------------------------------------
	## Content Split
	--------------------------------------------------------------*/
  .content-split {
    flex-direction: column;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
  }
  .content-split-media-wide {
    max-width: 100%;
  }
}

/*--------------------------------------------------------------
# Bootstrap
--------------------------------------------------------------*/
/*!
 * Bootstrap Grid v4.1.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@-ms-viewport {
  width: device-width;
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 641px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 1025px) {
  .container {
    max-width: 990px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1360px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 641px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1025px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxl-first {
    order: -1;
  }
  .order-xxl-last {
    order: 13;
  }
  .order-xxl-0 {
    order: 0;
  }
  .order-xxl-1 {
    order: 1;
  }
  .order-xxl-2 {
    order: 2;
  }
  .order-xxl-3 {
    order: 3;
  }
  .order-xxl-4 {
    order: 4;
  }
  .order-xxl-5 {
    order: 5;
  }
  .order-xxl-6 {
    order: 6;
  }
  .order-xxl-7 {
    order: 7;
  }
  .order-xxl-8 {
    order: 8;
  }
  .order-xxl-9 {
    order: 9;
  }
  .order-xxl-10 {
    order: 10;
  }
  .order-xxl-11 {
    order: 11;
  }
  .order-xxl-12 {
    order: 12;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66667%;
  }
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 641px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1025px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1400px) {
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 641px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1025px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1400px) {
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}

/*--------------------------------------------------------------
# Base
--------------------------------------------------------------*/
/*--------------------------------------------------------------
## Typography
--------------------------------------------------------------*/
body,
button,
input,
select,
optgroup,
textarea {
  color: #25282a;
  font-family: "Gilroy";
  font-size: 14px;
  line-height: 1.6;
}

blockquote {
  margin: 0 25px;
}

pre {
  max-width: 100%;
  padding: 1.6em;
  overflow: auto;
  background: #bebebe;
}

[class*='font-'] {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: 30px;
  line-height: 1;
}

/*--------------------------------------------------------------
## Base Layout
--------------------------------------------------------------*/
html, body {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  height: 100%;
}

html {
  box-sizing: border-box;
}

body.noscroll {
  overflow: hidden;
}

/*--------------------------------------------------------------
## Layout
--------------------------------------------------------------*/
.wrapper {
  width: 100%;
  max-width: 1800px;
  margin: 0 auto;
  padding: 0 15px;
}

.wrapper--xl {
  max-width: 1600px;
}

@media screen and (max-width: 1199px) {
  .wrapper--xl {
    max-width: 800px;
  }
}

.wrapper--md {
  max-width: 1520px;
}

.wrapper--mmd {
  max-width: 1415px;
}

.wrapper--mmmd {
  max-width: 1260px;
}

.wrapper--4m {
  max-width: 1200px;
}

.wrapper--sm {
  max-width: 945px;
}

.wrapper--xs {
  max-width: 1021px;
}

.wrapper--xxs {
  max-width: 975px;
}

.wrapper--xxxss {
  max-width: 800px;
}

.wrapper--xxxs {
  max-width: 720px;
}

/*--------------------------------------------------------------
## Animations
--------------------------------------------------------------*/
@media screen and (min-width: 768px) {
  .is-animate {
    backface-visibility: hidden;
    opacity: 0;
    will-change: transform;
  }
  .fade-in {
    transition: 800ms ease-in-out;
  }
  .slide-up {
    transform: translate3d(0, 200px, 0);
    transition: 1000ms ease-in-out;
  }
  .slide-left-xl {
    transform: translateX(-450px);
    transition: 1000ms ease-in-out;
  }
  .is-visible {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

/*--------------------------------------------------------------
# Parts
--------------------------------------------------------------*/
/*--------------------------------------------------------------
## Buttons
--------------------------------------------------------------*/
.btn {
  display: inline-block;
  padding: 15px 36px;
  transition: 350ms ease;
  font-size: 13px;
  text-align: center;
  text-transform: uppercase;
  border: none;
}

.btn:active, .btn:focus {
  outline: none;
}

@media screen and (max-width: 1199px) {
  .btn {
    padding: 7px 15px;
    font-size: 11px;
  }
}

.btn--primary {
  padding: 13px 30px;
  vertical-align: middle;
  background-color: #47d7ac;
  color: #fff;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 800;
  border-radius: 100px;
  transition: 350ms ease;
}

.btn--primary:hover {
  background-color: #fb637e;
}

@media screen and (max-width: 1199px) {
  .btn--primary {
    padding: 12px 34px;
  }
}

@media screen and (max-width: 767px) {
  .btn--primary {
    padding: 6px 20px;
    font-size: 14px;
    margin-bottom: 15px;
  }
}

.btn--secondary {
  padding: 14px 25px;
  background-color: #fb637e;
}

.btn--ghost {
  border: 1px solid #47d7ac;
  color: #47d7ac;
  background-color: #fff;
}

.btn--ghost:hover {
  color: #fff;
  border-color: #fb637e;
}

@media screen and (max-width: 767px) {
  .btn--ghost {
    padding: 10px 20px 11px;
    font-size: 14px;
  }
}

.btn--form {
  padding: 15px 62px;
}

@media screen and (max-width: 767px) {
  .btn--form {
    padding: 10px 30px;
  }
}

/*--------------------------------------------------------------
## Links
--------------------------------------------------------------*/
/*--------------------------------------------------------------
## Forms
--------------------------------------------------------------*/
input[type='text'],
input[type='email'],
input[type='tel'] {
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  outline: none;
  border: 2px solid #e6e7e9;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  input[type='text'],
  input[type='email'],
  input[type='tel'] {
    padding: 7px;
  }
}

textarea {
  height: 226px;
  width: 100%;
  border-radius: 6px;
  padding: 23px 32px;
  border: 2px solid #e6e7e9;
}

label,
.label {
  font-size: 18px;
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
  text-align: left;
}

@media screen and (max-width: 767px) {
  label,
  .label {
    font-size: 15px;
  }
}

.file-input {
  height: 235px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid #e6e7e9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-input input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input label {
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  font-weight: normal;
  margin: 0;
  color: #3b6cb3;
  margin-right: 7px;
}

@media screen and (max-width: 767px) {
  .file-input label {
    font-size: 15px;
  }
}

.file-input span {
  font-size: 18px;
  display: inline-block;
}

@media screen and (max-width: 767px) {
  .file-input span {
    font-size: 15px;
  }
}

/*--------------------------------------------------------------
## Wrapper and Ratios
--------------------------------------------------------------*/
img {
  max-width: 100%;
  display: block;
  height: initial;
}

/*--------------------------------------------------------------
# Components / Templates
--------------------------------------------------------------*/
.header {
  padding: 20px 0;
  position: fixed;
  width: 100%;
  z-index: 10;
  background-color: #f6f6f7;
  transition: 350ms ease;
}

@media screen and (max-width: 767px) {
  .header {
    padding: 9px 0;
  }
}

.header-fixed .header__logo img {
  width: 180px;
  transition: 350ms ease;
}

@media screen and (max-width: 767px) {
  .header-fixed .header__logo img {
    width: 100px;
  }
}

.header-fixed .main-nav a {
  font-size: 16px;
}

.header-fixed {
  padding: 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.header-fixed .header__actions a {
  padding: 19px 20px;
  font-size: 14px;
  line-height: 0;
}

.header-fixed .header__actions .menu-btn {
  width: 30px;
  height: 19px;
  margin-left: 15px;
}

.header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

@media screen and (max-width: 1199px) {
  .header__container {
    display: block;
  }
}

@media screen and (max-width: 1199px) {
  .header__wrap {
    display: none;
  }
}

.header__box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 1199px) {
  .header__box {
    height: 100vh;
    text-align: center;
    margin-top: 92px;
    display: block;
  }
}

.main-nav li {
  display: inline-block;
  padding: 30px 24px 30px 0;
}

.main-nav a {
  display: block;
  font-size: 18px;
  line-height: 1.16;
  color: #253746;
  font-weight: 500;
  transition: 350ms ease;
}

.main-nav a:hover {
  color: #fb637e;
}

@media screen and (max-width: 1199px) {
  .main-nav {
    display: block;
  }
  .main-nav > ul > li {
    display: block;
    padding: 30px 15px;
  }
  .main-nav > ul > li > a {
    font-size: 32px;
  }
}

@media screen and (max-width: 767px) {
  .main-nav > ul > li {
    padding: 20px 15px;
  }
  .main-nav > ul > li > a {
    font-size: 20px;
  }
}

.header__actions {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1199px) {
  .header__actions {
    margin-top: 20px;
    justify-content: center;
  }
}

.header__logo {
  display: inline-block;
}

.header__logo img {
  transition: 350ms ease;
  width: 270px;
}

@media screen and (max-width: 1199px) {
  .header__logo img {
    width: 200px;
  }
}

@media screen and (max-width: 767px) {
  .header__logo img {
    width: 142px;
  }
}

.banner {
  background-image: url("assets/images/blob.png");
  background-position: bottom;
  padding: 162px 0 200px;
  background-size: cover;
}

@media screen and (max-width: 1290px) {
  .banner {
    padding: 142px 0 200px;
  }
}

@media screen and (max-width: 1199px) {
  .banner {
    padding: 142px 0 110px;
  }
}

@media screen and (max-width: 767px) {
  .banner {
    padding: 77px 0;
  }
}

.banner--xl {
  padding: 309px 0 340px;
}

@media screen and (max-width: 1199px) {
  .banner--xl {
    padding: 142px 0 129px;
  }
}

@media screen and (max-width: 767px) {
  .banner--xl {
    padding: 83px 0 61px;
  }
}

.banner__video-container {
  text-align: center;
  line-height: 0;
}

.banner__video1 {
  height: auto;
}

@media screen and (max-width: 1199px) {
  .banner__video1 {
    width: 66%;
  }
}

@media screen and (max-width: 767px) {
  .banner__video1 {
    width: 100%;
  }
}

.banner__video2 {
  height: auto;
}

@media screen and (max-width: 1199px) {
  .banner__video2 {
    display: none;
  }
}

.banner__image-desc {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 83%;
  margin: 36px auto;
}

@media screen and (max-width: 1290px) {
  .banner__image-desc {
    bottom: 18px auto;
  }
}

@media screen and (max-width: 1199px) {
  .banner__image-desc {
    display: none;
  }
}

.banner__image-desc-left,
.banner__image-desc-right {
  flex: 0 0 50%;
  max-width: 50%;
  text-align: center;
}

.banner__image-desc-left span,
.banner__image-desc-right span {
  font-size: 24px;
  line-height: 1.2;
  color: #253746;
  display: block;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  .banner__image-desc-left span,
  .banner__image-desc-right span {
    font-size: 14px;
  }
}

.banner__title {
  font-size: 59px;
  line-height: 1.16;
  text-align: center;
  font-weight: 800;
  margin-bottom: 10px;
  font-family: "Gilroy";
  color: #253746;
}

@media screen and (max-width: 1500px) {
  .banner__title {
    font-size: 50px;
  }
}

@media screen and (max-width: 1199px) {
  .banner__title {
    font-size: 40px;
  }
}

@media screen and (max-width: 767px) {
  .banner__title {
    font-size: 30px;
  }
}

.banner__title--xl {
  margin-bottom: 50px;
}

@media screen and (max-width: 767px) {
  .banner__title--xl {
    margin-bottom: 25px;
  }
}

.banner__subtitle {
  font-size: 29px;
  line-height: 1.2;
  text-align: center;
  color: #253746;
  margin-bottom: 0;
}

@media screen and (max-width: 1500px) {
  .banner__subtitle {
    font-size: 28px;
  }
}

@media screen and (max-width: 1199px) {
  .banner__subtitle {
    font-size: 20px;
  }
}

@media screen and (max-width: 767px) {
  .banner__subtitle {
    font-size: 15px;
    line-height: 1.5;
  }
}

.banner__subtitle--xl {
  max-width: 1256px;
  margin: 0 auto 30px;
}

.banner__subtitle--l {
  max-width: 995px;
  margin: 0 auto 30px;
}

.banner__subtilte--md {
  max-width: 910px;
  margin: 0 auto;
}

.banner__subtitle--m {
  max-width: 741px;
  margin: 0 auto;
}

.banner__arrow {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  z-index: 10;
  cursor: pointer;
  transition: 350ms ease;
  bottom: -40px;
}

.banner__arrow .icon {
  color: #54bd92;
  transition: 350ms ease;
}

.banner__arrow .icon:hover {
  color: #fb637e;
}

@media screen and (max-width: 1500px) {
  .banner__arrow {
    bottom: -29px;
  }
}

@media screen and (max-width: 1290px) {
  .banner__arrow {
    bottom: -12px;
  }
}

@keyframes drop {
  0% {
    top: 5px;
    opacity: 0;
  }
  30% {
    top: 10px;
    opacity: 1;
  }
  100% {
    top: 25px;
    opacity: 0;
  }
}

.features {
  padding: 50px 0;
}

@media screen and (max-width: 767px) {
  .features {
    padding: 20px 0;
  }
}

.features__section {
  padding: 80px 0 0;
  position: relative;
}

@media screen and (max-width: 1199px) {
  .features__section {
    padding: 137px 0 150px;
  }
}

@media screen and (max-width: 767px) {
  .features__section {
    padding: 142px 0 112px;
  }
}

@media screen and (max-width: 551px) {
  .features__section {
    padding: 36px 0 69px;
  }
}

@media screen and (max-width: 767px) {
  .features__section--xx {
    padding: 100px 0 25px;
  }
}

.features__wrap {
  margin-top: 150px;
}

@media screen and (max-width: 1199px) {
  .features__wrap {
    margin-top: 0;
  }
}

.features__blob {
  position: absolute;
  top: -7%;
  max-width: 41%;
}

.features__blobs--right .features__blob {
  right: 0;
}

.features__blobs--left .features__blob {
  left: 0;
}

@media screen and (max-width: 1199px) {
  .features__blob {
    max-width: 39%;
  }
}

@media screen and (max-width: 767px) {
  .features__blob {
    top: 22%;
    max-width: 75%;
  }
}

@media screen and (max-width: 551px) {
  .features__blob {
    top: 21%;
    max-width: 75%;
  }
}

.features__blob2 {
  top: 5%;
}

@media screen and (max-width: 767px) {
  .features__blob2 {
    max-width: 60%;
    top: 25%;
  }
}

.features__blob3 {
  top: -10%;
}

@media screen and (max-width: 767px) {
  .features__blob3 {
    max-width: 54%;
    top: 31%;
  }
}

@media screen and (max-width: 551px) {
  .features__blob3 {
    top: 25%;
    max-width: 60%;
  }
}

.features__blob4 {
  top: 5%;
}

@media screen and (max-width: 1199px) {
  .features__blob4 {
    max-width: 38%;
    top: -13%;
  }
}

@media screen and (max-width: 767px) {
  .features__blob4 {
    max-width: 62%;
    top: 21%;
  }
}

@media screen and (max-width: 551px) {
  .features__blob4 {
    max-width: 52%;
    top: 39%;
  }
}

.features__blob--grey {
  max-width: 56%;
  top: -11%;
}

@media screen and (max-width: 1199px) {
  .features__blob--grey {
    top: -2%;
  }
}

@media screen and (max-width: 767px) {
  .features__blob--grey {
    max-width: 92%;
    top: 11%;
  }
}

@media screen and (max-width: 551px) {
  .features__blob--grey {
    top: 26%;
  }
}

.features__blob--grey2 {
  top: 0;
}

@media screen and (max-width: 767px) {
  .features__blob--grey2 {
    top: 26%;
  }
}

.features__blob--grey3 {
  top: -20%;
}

@media screen and (max-width: 767px) {
  .features__blob--grey3 {
    max-width: 93%;
    top: 22%;
  }
}

.features__blob--grey4 {
  top: -9%;
}

@media screen and (max-width: 1199px) {
  .features__blob--grey4 {
    max-width: 56%;
    top: -21%;
  }
}

@media screen and (max-width: 767px) {
  .features__blob--grey4 {
    max-width: 79%;
    top: 23%;
  }
}

@media screen and (max-width: 551px) {
  .features__blob--grey4 {
    top: 37%;
    width: 69px;
  }
}

.features__content {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  margin-top: -135px;
}

@media screen and (max-width: 1199px) {
  .features__content {
    margin-top: -87px;
  }
}

@media screen and (max-width: 767px) {
  .features__content {
    margin-top: -35px;
    display: block;
  }
}

.features__content-xl {
  padding: 150px 0;
}

@media screen and (max-width: 1199px) {
  .features__content-xl {
    padding: 0;
  }
}

.features__content--center {
  justify-content: center;
}

.features__content--left {
  justify-content: flex-start;
}

.features__content--left-margin {
  margin-left: 20px;
}

@media screen and (max-width: 767px) {
  .features__content--left-margin {
    margin-left: 30px;
  }
}

.features__content--flip .features__content-info {
  order: 1;
  text-align: left;
}

.features__content--flip .features__content-info::after {
  right: auto;
  left: 15px;
}

.features__content--flip .features__images--2 {
  order: 0;
}

.features__content-info {
  flex-shrink: 0;
  max-width: 553px;
}

.features__content-info h3 {
  font-size: 36px;
  line-height: 1.2;
  text-align: right;
  font-weight: bold;
  color: #253746;
  position: relative;
  padding: 35px 15px;
}

.features__content-info h3::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 15px;
  width: 361px;
  height: 3px;
  background-color: #fb637e;
}

@media screen and (max-width: 1199px) {
  .features__content-info {
    max-width: 322px;
  }
  .features__content-info h3 {
    font-size: 20px;
    padding: 18px 15px;
  }
  .features__content-info h3::after {
    width: 151px;
  }
}

@media screen and (max-width: 767px) {
  .features__content-info {
    max-width: 262px;
    margin: 0 auto;
  }
  .features__content-info h3 {
    font-size: 16px;
    padding: 20px 15px;
    text-align: center;
  }
  .features__content-info h3::after {
    width: 127px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.features__content-info--left {
  max-width: 665px;
}

.features__content-info--left h3 {
  text-align: left;
}

.features__content-info--left h3::after {
  left: 19px;
}

@media screen and (max-width: 1199px) {
  .features__content-info--left {
    max-width: 404px;
  }
}

@media screen and (max-width: 767px) {
  .features__content-info--left h3 {
    font-size: 16px;
    text-align: center;
  }
  .features__content-info--left h3::after {
    width: 127px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.features__content-info-xl {
  max-width: 701px;
}

.features__content-info-m {
  max-width: 668px;
}

@media screen and (max-width: 1199px) {
  .features__content-info-m {
    max-width: 393px;
  }
}

.features__content-info-l {
  max-width: 600px;
}

@media screen and (max-width: 1199px) {
  .features__content-info-l {
    max-width: 350px;
  }
}

.features__content-info--s {
  max-width: 550px;
}

.features__images {
  margin-left: 120px;
}

.features__content--flip .features__images {
  margin-right: 120px;
  margin-left: 0;
}

@media screen and (max-width: 1199px) {
  .features__images {
    margin-left: 60px;
  }
  .features__content--flip .features__images {
    margin-right: 60px;
    margin-left: 0;
  }
}

@media screen and (max-width: 767px) {
  .features__images {
    margin: 60px auto;
  }
  .features__content--flip .features__images {
    margin-right: 20px;
    margin-left: 0;
  }
}

.features__img {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.features__img-animate {
  position: absolute;
  top: 3%;
  right: 8%;
  z-index: -1;
  max-width: 85%;
}

.features__img-tablet-animate {
  position: absolute;
  top: 4%;
  z-index: -1;
  width: 83.2%;
  left: 9%;
}

.features__img-tablet-animate img {
  width: 100%;
}

.features__img-headset-animate {
  position: absolute;
  top: 3%;
  right: 2.2%;
  width: 31.3%;
  z-index: -1;
}

.features__images--1 {
  max-width: 19%;
}

@media screen and (max-width: 767px) {
  .features__images--1 {
    max-width: 54%;
  }
}

.features__images--2 {
  max-width: 45%;
}

@media screen and (max-width: 767px) {
  .features__images--2 {
    max-width: 84%;
  }
}

.features__images--3 {
  max-width: 49%;
}

@media screen and (max-width: 767px) {
  .features__images--3 {
    max-width: 95%;
  }
}

.features__images--4 {
  max-width: 46%;
}

@media screen and (max-width: 767px) {
  .features__images--4 {
    max-width: 91%;
  }
}

@media screen and (max-width: 1199px) {
  .section-head {
    padding: 0 25px;
  }
}

@media screen and (max-width: 767px) {
  .section-head {
    padding: 0;
  }
}

.section-head--center {
  text-align: center;
}

.section-head__title {
  font-size: 42px;
  line-height: 1.16;
  font-family: "Gilroy";
  font-weight: 800;
  margin-bottom: 20px;
  color: #253746;
}

@media screen and (max-width: 1199px) {
  .section-head__title {
    font-size: 30px;
  }
}

@media screen and (max-width: 767px) {
  .section-head__title {
    font-size: 21px;
  }
}

@media screen and (max-width: 1199px) {
  .section-head__title--right {
    font-size: 23px;
  }
}

@media screen and (max-width: 767px) {
  .section-head__title--right {
    text-align: center;
  }
}

.section-head__title--sm {
  max-width: 834px;
  width: 100%;
  margin: 0 auto 70px;
}

@media screen and (max-width: 767px) {
  .section-head__title--sm {
    font-size: 18px;
    margin: 0 auto 40px;
    max-width: 641px;
  }
}

.section-head__subtitle {
  font-size: 18px;
  line-height: 1.4;
  font-family: "Gilroy";
  color: #afb2bd;
  max-width: 690px;
  width: 100%;
}

@media screen and (max-width: 1199px) {
  .section-head__subtitle {
    font-size: 17px;
    max-width: 648px;
  }
}

@media screen and (max-width: 767px) {
  .section-head__subtitle {
    font-size: 14px;
    line-height: 1.4;
  }
}

.section-head__subtitle--center {
  margin: 0 auto;
}

.section-head__subtitle--md {
  max-width: 455px;
}

.section-head__subtitle--xs {
  max-width: 448px;
}

.section-head__subtitle--xxs {
  max-width: 262px;
}

@media screen and (max-width: 767px) {
  .section-head__subtitle--xxs {
    text-align: center;
    margin: 0 auto;
  }
}

.intro {
  padding: 80px 0;
}

@media screen and (max-width: 1199px) {
  .intro {
    padding: 40px 0;
  }
}

@media screen and (max-width: 767px) {
  .intro {
    padding: 25px 0 0;
  }
}

.intro__container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.intro__item {
  flex: 0 0 33.3%;
  max-width: 33%;
  margin: 50px 0;
}

.intro__item img {
  max-width: 77px;
  height: 83px;
  margin: 0 auto 25px;
}

@media screen and (max-width: 1199px) {
  .intro__item {
    margin: 35px 0;
  }
}

@media screen and (max-width: 767px) {
  .intro__item {
    flex: 0 0 100%;
    max-width: 100%;
    margin: 20px 0;
  }
  .intro__item img {
    height: 52px;
  }
}

.intro__item-title {
  font-size: 18px;
  line-height: 1.16;
  font-family: "Gilroy";
  font-weight: 800;
  text-align: center;
  margin-bottom: 12px;
}

@media screen and (max-width: 767px) {
  .intro__item-title {
    font-size: 16px;
  }
}

.intro__item-content {
  font-size: 18px;
  line-height: 1.8;
  color: #afb2bd;
  text-align: center;
  max-width: 280px;
  padding: 0 15px;
  width: 100%;
  margin: 0 auto;
}

@media screen and (max-width: 1199px) {
  .intro__item-content {
    font-size: 15px;
  }
}

.awards {
  padding: 110px 0 170px;
}

@media screen and (max-width: 1199px) {
  .awards {
    padding: 78px 0 70px;
  }
}

@media screen and (max-width: 767px) {
  .awards {
    padding: 50px 0;
  }
}

.awards__container {
  display: flex;
  justify-content: center;
  margin-top: 36px;
}

@media screen and (max-width: 767px) {
  .awards__container {
    padding: 0 35px;
  }
}

.award-item {
  margin: 0 15px;
  flex: 0 0 20%;
  max-width: 20%;
}

@media screen and (max-width: 767px) {
  .award-item img {
    width: 100px;
  }
}

.quotes {
  position: relative;
  padding: 150px 0;
}

@media screen and (max-width: 1199px) {
  .quotes {
    padding: 100px 0;
  }
}

@media screen and (max-width: 767px) {
  .quotes {
    padding: 15px 0;
  }
}

.quotes__blobs {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  width: 51%;
}

@media screen and (max-width: 767px) {
  .quotes__blobs {
    display: none;
  }
}

.quotes__blobs--grey {
  position: absolute;
  bottom: -8%;
  right: 16%;
  z-index: -1;
  width: 100%;
}

.quote__section-head-title {
  max-width: 319px;
}

@media screen and (max-width: 767px) {
  .quote__section-head-title {
    text-align: center;
    max-width: 100%;
  }
}

.quotes__container {
  margin-top: 64px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
}

@media screen and (max-width: 767px) {
  .quotes__container {
    display: block;
    margin-top: 22px;
  }
}

.quotes__items {
  flex-shrink: 0;
}

@media screen and (max-width: 767px) {
  .quotes__items {
    margin-bottom: 30px;
  }
}

.quotes__images {
  border-bottom: 5px solid #47d7ac;
  margin: 0 50px;
  z-index: 1;
  position: relative;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .quotes__images {
    max-width: 190px;
    margin: 0 auto 30px;
  }
}

.quotes__image {
  position: absolute;
  bottom: 0;
  opacity: 0;
  transition: 400ms ease;
}

.quotes__image:first-of-type {
  position: relative;
}

.quotes__image.active {
  opacity: 1;
  transition: 400ms 200ms ease;
}

.quote__item {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 15px;
  position: relative;
  padding: 20px;
  cursor: pointer;
}

.quote__item:last-of-type {
  margin-bottom: 0;
}

.quote__item::before {
  content: '';
  background-color: #f6f6f7;
  position: absolute;
  width: 300%;
  height: 100%;
  left: 0;
  border-radius: 50px;
  opacity: 0;
  transition: 350ms ease;
}

.quote__item.active::before {
  opacity: 1;
}

@media screen and (max-width: 767px) {
  .quote__item {
    flex-direction: row-reverse;
    padding: 11px;
  }
  .quote__item::before {
    width: 100%;
  }
}

.quote__item-info {
  position: relative;
  padding-right: 15px;
  text-align: right;
}

.quote__item-info::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 3px;
  background-color: #fb637e;
  opacity: 0.3;
  transition: 350ms ease;
}

.quote__item-info span {
  display: block;
  font-size: 20px;
  line-height: 1.16;
  font-weight: 800;
  color: #253746;
  opacity: 0.3;
  transition: 350ms ease;
}

@media screen and (max-width: 1199px) {
  .quote__item-info span {
    font-size: 15px;
  }
}

.active .quote__item-info::before {
  opacity: 1;
}

.active .quote__item-info span {
  opacity: 1;
}

@media screen and (max-width: 767px) {
  .quote__item-info {
    padding-right: 0;
    padding-left: 15px;
    text-align: left;
    margin-left: 10px;
  }
  .quote__item-info::before {
    right: auto;
    left: 0;
  }
}

.quote__item-info-icon img {
  padding-left: 15px;
  opacity: 0.6;
  height: 39px;
  transition: 350ms ease;
  position: relative;
}

.active .quote__item-info-icon img {
  opacity: 1;
}

.quotes__contents {
  max-width: 481px;
  z-index: 1;
  position: relative;
}

@media screen and (max-width: 767px) {
  .quotes__contents {
    text-align: center;
    margin: 0 auto;
  }
}

.quotes__content {
  position: absolute;
  top: 0;
  opacity: 0;
  transition: 400ms ease;
  transform: translateY(20px);
}

.quotes__content::before {
  content: "";
  font-family: "fonticons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  line-height: 1;
  position: absolute;
  top: 0;
  left: 0;
  color: #25282a;
  font-size: 70px;
  transform: translate(-18px, -30px);
  opacity: 0.2;
}

.quotes__content:first-of-type {
  position: relative;
}

.quotes__content.active {
  opacity: 1;
  transform: translateY(0);
  transition: 400ms 200ms ease;
}

.quotes__content-text {
  font-size: 24px;
  line-height: 1.45;
  font-family: "Gilroy";
  color: #fff;
  position: relative;
  font-weight: 600;
}

@media screen and (max-width: 1199px) {
  .quotes__content-text {
    font-size: 18px;
  }
}

@media screen and (max-width: 1024px) {
  .quotes__content-text {
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  .quotes__content-text {
    color: #253746;
  }
}

.brands {
  padding: 170px 0 120px;
}

@media screen and (max-width: 1199px) {
  .brands {
    padding: 111px 0 120px;
  }
}

@media screen and (max-width: 1024px) {
  .brands {
    padding: 100px 0 70px;
  }
}

@media screen and (max-width: 767px) {
  .brands {
    padding: 50px 0 18px;
  }
}

.brands__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 88px;
}

@media screen and (max-width: 767px) {
  .brands__container {
    margin-top: 20px;
  }
}

.brand__item {
  max-width: 20%;
  padding: 0 33px;
}

.brand__item img {
  width: 100%;
}

@media screen and (max-width: 1199px) {
  .brand__item {
    padding: 0 15px;
  }
  .brand__item img {
    margin: 0 auto 20px;
    max-width: 110px;
  }
}

@media screen and (max-width: 767px) {
  .brand__item {
    flex: 0 0 33%;
    max-width: 33%;
  }
}

.cta {
  padding: 240px 0 100px;
}

@media screen and (max-width: 1199px) {
  .cta {
    padding: 125px 0 53px;
  }
}

@media screen and (max-width: 767px) {
  .cta {
    padding: 80px 0 16px;
  }
}

.cta--lg {
  padding: 40px 0 240px;
}

@media screen and (max-width: 1199px) {
  .cta--lg {
    padding: 40px 0 120px;
  }
}

@media screen and (max-width: 767px) {
  .cta--lg {
    padding: 98px 0 50px;
  }
}

@media screen and (max-width: 551px) {
  .cta--lg {
    padding: 60px 0 50px;
  }
}

.cta--bg-blob {
  background-image: url("assets/images/cta-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.cta--sm-blob {
  padding: 141px 0 100px;
  background-image: url("assets/images/cta-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 767px) {
  .cta--sm-blob {
    padding: 94px 0 0;
  }
}

.cta__btn {
  text-align: center;
  margin-top: 60px;
}

.cta__btn .btn {
  margin-right: 20px;
}

.cta__btn .btn:last-of-type {
  margin-right: 0;
}

@media screen and (max-width: 767px) {
  .cta__btn {
    margin-top: 30px;
  }
  .cta__btn .btn {
    margin-right: 0;
  }
}

.footer {
  padding: 200px 0 71px;
  background-image: url("assets/images/footer-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #f3f3f4;
  position: relative;
}

@media screen and (max-width: 1199px) {
  .footer {
    position: relative;
  }
}

@media screen and (max-width: 767px) {
  .footer {
    padding: 120px 0 40px;
  }
}

.footer__image {
  position: absolute;
  top: -40%;
  right: 11%;
  width: 25%;
}

@media screen and (max-width: 1500px) {
  .footer__image {
    top: -20%;
  }
}

@media screen and (max-width: 1290px) {
  .footer__image {
    top: -18%;
  }
}

@media screen and (max-width: 1199px) {
  .footer__image {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .footer__item {
    margin-bottom: 50px;
  }
  .footer__item:last-of-type {
    margin-bottom: 0;
  }
}

.footer__container {
  display: flex;
  justify-content: space-between;
  max-width: 840px;
}

@media screen and (max-width: 767px) {
  .footer__container {
    display: block;
  }
}

.footer__item-logo img {
  transform: translateY(-19px);
}

@media screen and (max-width: 1199px) {
  .footer__item-logo img {
    transform: none;
    max-width: 220px;
  }
}

@media screen and (max-width: 767px) {
  .footer__item-logo img {
    max-width: 150px;
  }
}

.footer__item-title {
  color: #fff;
  font-size: 24px;
  line-height: 1.16;
  font-weight: 700;
  margin-bottom: 35px;
}

@media screen and (max-width: 767px) {
  .footer__item-title {
    font-size: 19px;
    margin-bottom: 20px;
  }
}

.footer__item-list a {
  color: #afb2bd;
  font-size: 18px;
  line-height: 1.16;
  display: block;
  margin-bottom: 20px;
}

.footer__item-list a:hover {
  color: #fff;
}

@media screen and (max-width: 1199px) {
  .footer__item-list a a {
    font-size: 15px;
  }
}

.footer__item-logo-info span {
  display: block;
  font-size: 20px;
  line-height: 1.16;
  font-weight: 800;
  color: #fff;
  margin: 80px 0 30px;
}

.footer__item-media {
  display: flex;
  margin-bottom: 90px;
}

.footer__item-media .icon {
  color: #afb2bd;
  transition: 350ms ease;
}

.footer__item-media .icon:hover {
  color: #fff;
}

.footer__item-media a {
  margin-right: 13px;
}

.footer__item-media a:last-of-type {
  margin-right: 0;
}

.footer__item-media a:hover {
  color: #fff;
}

@media screen and (max-width: 1199px) {
  .footer__item-media {
    margin-bottom: 46px;
  }
}

.copyright {
  font-size: 18px;
  line-height: 1.35;
  color: #afb2bd;
  display: block;
  margin-bottom: 5px;
}

.copyright:last-of-type {
  margin-bottom: 0;
}

.menu-btn {
  display: none;
  position: absolute;
  z-index: 110;
  top: 28px;
  right: 22px;
  width: 25px;
  height: 19px;
}

.menu-btn::after, .menu-btn::before,
.menu-btn span {
  display: block;
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #54bd92;
}

.menu-btn::after, .menu-btn::before {
  content: '';
  transition: 350ms width 350ms ease;
}

.menu-btn::after {
  top: 0;
  left: 0;
}

.menu-btn::before {
  right: 0;
  bottom: 0;
  width: 25px;
}

.menu-btn span {
  top: 50%;
  margin-top: -1.5px;
  transition: transform 350ms ease;
  width: 30px;
  right: 0;
}

.menu-btn span::before {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  height: 3px;
  transition: transform 350ms ease;
  background-color: #54bd92;
}

.menu-btn.open::after, .menu-btn.open::before,
.menu-btn.open span {
  background-color: #54bd92;
}

.menu-btn.open::after, .menu-btn.open::before {
  width: 0;
  transition: 350ms ease;
}

.menu-btn.open::after {
  left: 0;
}

.menu-btn.open::before {
  right: 0;
}

.menu-btn.open span {
  transform: rotate(45deg);
  transition: 350ms transform 350ms ease;
}

.menu-btn.open span::before {
  transform: rotate(-90deg);
  transition: 350ms transform 350ms ease;
  background-color: #54bd92;
}

@media screen and (max-width: 1199px) {
  .menu-btn {
    display: block;
  }
  .menu-btn span {
    width: 23px;
  }
}

@media screen and (max-width: 767px) {
  .menu-btn {
    top: 14px;
  }
}

.about {
  padding: 80px 0;
  position: relative;
}

@media screen and (max-width: 1199px) {
  .about {
    padding: 0 0 30px;
  }
}

@media screen and (max-width: 767px) {
  .about {
    padding: 192px 0 60px;
  }
}

@media screen and (max-width: 551px) {
  .about {
    padding: 50px 0 100px;
  }
}

.about__body {
  position: relative;
}

@media screen and (max-width: 767px) {
  .about__body {
    z-index: 1;
  }
}

.about__container {
  padding: 100px 0;
  position: relative;
  backface-visibility: hidden;
}

@media screen and (max-width: 1199px) {
  .about__container {
    padding: 100px 0;
  }
}

@media screen and (max-width: 767px) {
  .about__container {
    padding: 0 0 50px;
    display: grid;
  }
}

.about__blob {
  position: absolute;
  top: 8%;
  max-width: 41%;
  z-index: 1;
}

.about__blobs--right .about__blob {
  right: 0;
}

@media screen and (max-width: 1500px) {
  .about__blob {
    top: 16%;
  }
}

@media screen and (max-width: 1199px) {
  .about__blob {
    top: 10%;
  }
}

@media screen and (max-width: 767px) {
  .about__blob {
    top: 62%;
  }
}

.about__blob2 {
  top: 0;
}

@media screen and (max-width: 1199px) {
  .about__blob2 {
    top: 17%;
  }
}

@media screen and (max-width: 767px) {
  .about__blob2 {
    top: 44%;
    max-width: 41%;
  }
}

@media screen and (max-width: 551px) {
  .about__blob2 {
    top: 49%;
    max-width: 36%;
  }
}

.about__blob--grey {
  max-width: 40%;
  top: 10%;
}

@media screen and (max-width: 1199px) {
  .about__blob--grey {
    max-width: 50%;
    top: -4%;
  }
}

@media screen and (max-width: 767px) {
  .about__blob--grey {
    top: 50%;
  }
}

@media screen and (max-width: 551px) {
  .about__blob--grey {
    top: 62%;
  }
}

.about__blob--grey2 {
  top: 0;
}

@media screen and (max-width: 1199px) {
  .about__blob--grey2 {
    width: 50%;
    top: 5%;
  }
}

@media screen and (max-width: 767px) {
  .about__blob--grey2 {
    width: 43%;
    top: 46%;
  }
}

@media screen and (max-width: 551px) {
  .about__blob--grey2 {
    width: 45%;
    top: 43%;
  }
}

.about__images {
  position: absolute;
  width: 50%;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .about__images {
    position: relative;
    width: 100%;
    top: 0;
    transform: translateY(0);
    order: 1;
  }
}

@media screen and (max-width: 551px) {
  .about__images {
    transform: translateY(15%);
  }
}

@media screen and (max-width: 767px) {
  .about__wrap-box {
    position: relative;
    width: 100%;
    top: 0;
    transform: translateY(0);
    margin-bottom: 5px;
    order: 0;
  }
}

.about__image {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.about__image img {
  transition: 350ms ease;
}

.about__image.active img {
  transform: translateY(-10px);
}

@media screen and (max-width: 767px) {
  .about__image {
    top: 0;
    transform: translateY(0);
  }
}

@media screen and (max-width: 767px) {
  .about__image-main {
    position: relative;
  }
}

.about__image-sm {
  z-index: 1;
}

.about__head {
  max-width: 362px;
  text-align: right;
  margin-bottom: 60px;
}

@media screen and (max-width: 767px) {
  .about__head {
    max-width: 100%;
    margin-bottom: 20px;
    text-align: center;
  }
}

.about__info {
  z-index: -1;
}

@media screen and (max-width: 767px) {
  .about__info {
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.about__item {
  border-radius: 100px 0 0 100px;
  margin-bottom: 50px;
  padding: 20px;
  transition: 350ms ease;
  cursor: pointer;
}

.about__item:last-of-type {
  margin-bottom: 0;
}

.about__item:first-of-type .about__item-inner::after {
  background-color: #3b6cb3;
}

.about__item:last-of-type .about__item-inner::after {
  background-color: #fb637e;
}

.about__item:nth-of-type(2) .about__item-inner::after {
  background-color: #47d7ac;
}

.about__item.active {
  background-color: #f6f6f7;
}

.about__item.active .about__item-inner {
  transform: translateX(-20px);
}

@media screen and (max-width: 1199px) {
  .about__item {
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 767px) {
  .about__item {
    border-radius: 0;
    margin-bottom: 6px;
    padding: 6px;
    flex: 0 0 33%;
    max-width: 33%;
  }
  .about__item.active .about__item-inner {
    transform: translateX(0);
  }
}

.about__item-inner {
  max-width: 283px;
  width: 100%;
  position: relative;
  padding: 15px 20px;
  transition: 350ms ease;
  text-align: right;
  margin-left: 50px;
}

.about__item-inner::after {
  content: '';
  top: 0;
  right: 0;
  position: absolute;
  height: 100%;
  width: 3px;
  background-color: #000;
}

@media screen and (max-width: 767px) {
  .about__item-inner {
    padding: 10px 0;
    margin-left: auto;
  }
  .about__item-inner::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 50%;
    height: 2px;
    background-color: #000;
  }
  .about__item-inner::after {
    display: none;
  }
}

.about__item-content {
  font-size: 24px;
  line-height: 1.16;
  font-weight: 800;
  transition: 350ms ease;
  color: #253746;
}

@media screen and (max-width: 1199px) {
  .about__item-content {
    font-size: 19px;
    max-width: 224px;
    width: 100%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 767px) {
  .about__item-content {
    font-size: 12px;
    text-align: center;
    max-width: 190px;
  }
}

@media screen and (max-width: 551px) {
  .about__item-content {
    font-size: 11px;
  }
}

.about__btn {
  max-width: 360px;
  text-align: right;
  z-index: 1;
  margin-top: 70px;
}

@media screen and (max-width: 767px) {
  .about__btn {
    max-width: 100%;
    margin-top: 0;
    text-align: center;
  }
}

.preloader {
  height: 100%;
  width: 100%;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  transition: 350ms ease;
  visibility: visible;
  opacity: 1;
}

.preloader.loaded {
  opacity: 0;
  visibility: hidden;
}

.preloader__img {
  width: 70px;
  height: 58px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.85);
  animation: fade 1400ms linear infinite;
  transform-origin: center;
  opacity: 0;
}

@keyframes fade {
  70% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

.team {
  padding: 65px 0;
}

@media screen and (max-width: 1199px) {
  .team {
    padding-top: 50px;
  }
}

@media screen and (max-width: 767px) {
  .team {
    padding: 25px 0 0;
  }
}

.team__container-first {
  display: flex;
  justify-content: center;
  margin-top: 55px;
}

.team__item-first {
  flex: 0 0 50%;
  max-width: 50%;
  border-radius: 50%;
}

.team__item-first img {
  width: 143px;
  height: 143px;
  margin: 0 auto 40px;
  transition: 350ms ease;
  transform: scale(1);
}

.team__item-first img:hover {
  transform: scale(1.1);
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .team__item-first img {
    width: 100px;
    height: 100px;
    margin: 0 auto 10px;
  }
}

.team__container-second {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 140px;
}

@media screen and (max-width: 1199px) {
  .team__container-second {
    margin-top: 50px;
  }
}

@media screen and (max-width: 767px) {
  .team__container-second {
    margin-top: 34px;
  }
}

.team__item-second {
  flex: 0 0 25%;
  max-width: 25%;
  margin-bottom: 200px;
}

.team__item-second:last-of-type {
  margin-bottom: 80px;
}

.team__item-second img {
  width: 143px;
  height: 143px;
  margin: 0 auto 40px;
  transition: 350ms ease;
  transform: scale(1);
}

.team__item-second img:hover {
  transform: scale(1.1);
  cursor: pointer;
}

@media screen and (max-width: 1199px) {
  .team__item-second {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) {
  .team__item-second {
    margin-bottom: 34px;
  }
  .team__item-second:last-of-type {
    margin-bottom: 20px;
  }
  .team__item-second:nth-last-of-type(2) {
    margin-bottom: 20px;
  }
  .team__item-second img {
    width: 100px;
    height: 100px;
    margin: 0 auto 10px;
  }
}

.team__item-second-info {
  text-align: center;
}

.team__item-second-info-name {
  font-size: 27px;
  line-height: 1.2;
  font-weight: bold;
  margin-bottom: 6px;
  color: #253746;
}

@media screen and (max-width: 1199px) {
  .team__item-second-info-name {
    font-size: 20px;
  }
}

@media screen and (max-width: 767px) {
  .team__item-second-info-name {
    font-size: 14px;
  }
}

.team__item-second-info-position {
  display: block;
  font-size: 25px;
  line-height: 1.4;
  color: #253746;
}

@media screen and (max-width: 1199px) {
  .team__item-second-info-position {
    font-size: 18px;
  }
}

@media screen and (max-width: 767px) {
  .team__item-second-info-position {
    font-size: 13px;
  }
}

.contact__form {
  background-color: #fff;
  box-shadow: 0 2px 50px rgba(76, 76, 76, 0.2);
  transform: translateY(-20%);
  padding: 45px 0;
  border-radius: 60px;
}

@media screen and (max-width: 1199px) {
  .contact__form {
    transform: translateY(-10%);
  }
}

@media screen and (max-width: 767px) {
  .contact__form {
    transform: translateY(0);
    box-shadow: none;
    padding: 35px 0 0;
  }
}

.contact__form-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 0 25px;
}

@media screen and (max-width: 767px) {
  .contact__form-container {
    display: block;
  }
}

.contact__wrap {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 20px 50px 0;
  flex-direction: column;
}

@media screen and (max-width: 767px) {
  .contact__wrap {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 15px;
  }
}

.contact__wrap--xl {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 20px 50px 0;
}

@media screen and (max-width: 767px) {
  .contact__wrap--xl {
    padding: 0;
  }
}

.contact__wrap-message {
  padding: 20px 50px;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .contact__wrap-message {
    padding: 0;
  }
}

.contact__btn {
  text-align: center;
  margin-top: 20px;
}

.careers-m {
  padding: 119px 0 19px;
}

@media screen and (max-width: 767px) {
  .careers-m {
    padding: 42px 0 19px;
  }
}

.careers-m__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (max-width: 1199px) {
  .careers-m__container {
    align-items: unset;
  }
}

.careers-m__item {
  flex: 0 0 33.3%;
  max-width: 33%;
  position: relative;
  padding-bottom: 35px;
  margin-bottom: 100px;
}

.careers-m__item a {
  font-size: 24px;
  line-height: 1.2;
  font-weight: bold;
  display: block;
  color: #253746;
  transition: 350ms ease;
}

.careers-m__item::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 220px;
  height: 3px;
  background-color: #cecece;
  transition: 350ms ease;
}

.careers-m__item:hover a {
  color: #fb637e;
}

.careers-m__item:hover::after {
  background-color: #fb637e;
}

@media screen and (max-width: 1199px) {
  .careers-m__item a {
    text-align: center;
  }
  .careers-m__item::after {
    left: 50%;
    transform: translate(-50%);
  }
}

@media screen and (max-width: 767px) {
  .careers-m__item {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 30px;
    padding-bottom: 7px;
  }
  .careers-m__item a {
    font-size: 14px;
    max-width: 155px;
    width: 100%;
    margin: 0 auto;
  }
  .careers-m__item::after {
    width: 71%;
  }
}

.entry-content p {
  font-size: 16px;
  line-height: 1.37;
  color: #253746;
  margin-bottom: 30px;
}

.entry-content ul li {
  list-style-type: disc;
  list-style-position: inside;
}

.entry-content ul li ul {
  padding: 0 15px;
}

@media screen and (max-width: 767px) {
  .entry-content p {
    font-size: 15px;
  }
}

.job-desc {
  padding: 70px 0;
}

@media screen and (max-width: 767px) {
  .job-desc {
    padding: 40px 0 0;
  }
}

.job-desc__wrap {
  margin-bottom: 50px;
}

.job-desc__title {
  font-size: 18px;
  line-height: 1.2;
  color: #253746;
  margin-bottom: 30px;
  font-weight: bold;
}

.job-desc__btn {
  text-align: center;
  margin-top: 70px;
}

@media screen and (max-width: 767px) {
  .job-desc__btn {
    margin-top: 0;
  }
}
