.brands {
    padding: 170px 0 120px;
	
    @include mq($tablet-l) {
        padding: 111px 0 120px;
    }
	
    @include mq($tablet) {
        padding: 100px 0 70px;
    }
	
	@include mq($phone) {
        padding: 50px 0 18px;
    }
}

.brands__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 88px;
	
    @include mq($phone) {
        margin-top: 20px;
    }
}

.brand__item {
    // flex: 0 0 20%;
    max-width: 20%;
    padding: 0 33px;

    img {
        width: 100%;
    }
	
    @include mq($tablet-l) {
        padding: 0 15px;

        img {
            margin: 0 auto 20px;
            max-width: 110px;
        }
    }
	
    @include mq($phone) {
        flex: 0 0 33%;
        max-width: 33%;
    }
}