.cta {
    padding: 240px 0 100px;

    @include mq($tablet-l) {
        padding: 125px 0 53px;
    }
	
    @include mq($phone) {
        padding: 80px 0 16px;
    }
}

.cta--lg {
    padding: 40px 0 240px;
    
    @include mq($tablet-l) {
        padding: 40px 0 120px;
    }
	
	@include mq($phone) {
        padding: 98px 0 50px;
    }

    @include mq($phone-s) {
        padding: 60px 0 50px;
    }
}

.cta--bg-blob {
	background-image: url('assets/images/cta-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
}

.cta--sm-blob {
    padding: 141px 0 100px;
    background-image: url('assets/images/cta-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;

    @include mq($phone) {
        padding: 94px 0 0;
    }
}

.cta__btn {
    text-align: center;
    margin-top: 60px;
	
	.btn {
		margin-right: 20px;
		
		&:last-of-type {
			margin-right: 0;
		}
	}

    @include mq($phone) {
        margin-top: 30px;

        .btn {
            margin-right: 0;
        }
    }
}