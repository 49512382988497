@font-face {
	font-family: "fonticons";
	src: url('assets/icons/fonticons.woff2') format('woff2'), url('assets/icons/fonticons.woff') format('woff'), url('assets/icons/fonticons.ttf') format('truetype');;
}

@mixin font-styles {
	font-family: "fonticons";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	// speak: none; // only necessary if not using the private unicode range (firstGlyph option)
	text-decoration: none;
	text-transform: none;
}

%font {
	@include font-styles;
}

@function font-char($filename) {
	$char: "";

	@if $filename == arrow-down {
		$char: "\E001";
	}
	@if $filename == facebook {
		$char: "\E002";
	}
	@if $filename == insta {
		$char: "\E003";
	}
	@if $filename == linkedin {
		$char: "\E004";
	}
	@if $filename == medium {
		$char: "\E005";
	}
	@if $filename == paperclip {
		$char: "\E006";
	}
	@if $filename == quotes {
		$char: "\E007";
	}
	@if $filename == twitter {
		$char: "\E008";
	}

	@return $char;
}

@mixin font($filename, $insert: before, $extend: true) {
	&:#{$insert} {
		@if $extend {
			@extend %font;
		} @else {
			@include font-styles;
		}
		content: font-char($filename);
	}
}

.font-arrow-down {
	@include font(arrow-down);
}
.font-facebook {
	@include font(facebook);
}
.font-insta {
	@include font(insta);
}
.font-linkedin {
	@include font(linkedin);
}
.font-medium {
	@include font(medium);
}
.font-paperclip {
	@include font(paperclip);
}
.font-quotes {
	@include font(quotes);
}
.font-twitter {
	@include font(twitter);
}

