.intro {
    padding: 80px 0;
	
    @include mq($tablet-l) {
        padding: 40px 0;
    }
	
    @include mq($phone) {
        padding: 25px 0 0;
    }
}

.intro__container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.intro__item {
    flex: 0 0 33.3%;
    max-width: 33%;
    margin: 50px 0;
    
    img {
        max-width: 77px;
        height: 83px;
        margin: 0 auto 25px;
    }
	
    @include mq($tablet-l) {
        margin: 35px 0;
    }
	
    @include mq($phone) {
        flex: 0 0 100%;
        max-width: 100%;
        margin: 20px 0;

        img {
            height: 52px;
        }
    }
}

.intro__item-title {
    font-size: 18px;
    line-height: 1.16;
    font-family: $font-main;
    font-weight: 800;
    text-align: center;
    margin-bottom: 12px;
	
    @include mq($phone) {
        font-size: 16px;
    }
}

.intro__item-content {
    font-size: 18px;
    line-height: 1.8;
    color: $mischka;
    text-align: center;
    max-width: 280px;
    padding: 0 15px;
    width: 100%;
    margin: 0 auto;
	
    @include mq($tablet-l) {
        font-size: 15px;
    }
}