/*--------------------------------------------------------------
## Buttons
--------------------------------------------------------------*/
.btn {
    display: inline-block;
    padding: 15px 36px;
    transition: $dur $ease;
    font-size: 13px;
    text-align: center;
    text-transform: uppercase;
	border: none;

    &:active,
    &:focus {
        outline: none;
    }

    @include mq($tablet-l) {
        padding: 7px 15px;
        font-size: 11px;
    }
}

.btn--primary {
    padding: 13px 30px;
    vertical-align: middle;
    background-color: $shamrock;
    color: $white;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 800;
    border-radius: 100px;
    transition: $dur $ease;

    &:hover {
        background-color: $brink-pink;
    }

    @include mq($tablet-l) {
        padding: 12px 34px;
    }

    @include mq($phone) {
        padding: 6px 20px;
        font-size: 14px;
        margin-bottom: 15px;
    }
}

.btn--secondary {
    padding: 14px 25px;
    background-color: $brink-pink;
}

.btn--ghost {
    border: 1px solid $shamrock;
    color: $shamrock;
	background-color: $white;

    &:hover {
        color: $white;
		border-color: $brink-pink;
    }

    @include mq($phone) {
        padding: 10px 20px 11px;
        font-size: 14px;
    }
}

.btn--form {
    padding: 15px 62px;
    
    @include mq($phone) {
        padding: 10px 30px;
    }
}