/*--------------------------------------------------------------
## Reset
--------------------------------------------------------------*/

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, fieldset, input, textarea, p, blockquote, th, td {
	margin: 0;
	padding: 0;
}
html { -webkit-font-smoothing: antialiased; }
h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section { display: block; }
audio, canvas, video { display: inline-block; *display: inline; *zoom: 1; }
audio:not([controls]) { display: none; }
[hidden] { display: none; }

/*--------------------------------------------------------------
## Base Reset
--------------------------------------------------------------*/

html { font-size: 100%; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; }
html, button, input, select, textarea { font-family: sans-serif; color: #222; }
body { margin: 0; font-size: 1em; line-height: 1.4; -moz-osx-font-smoothing: grayscale;}
::-moz-selection { background: #000; color: #fff; text-shadow: none; }
::selection { background: #000; color: #fff; text-shadow: none; }

/*--------------------------------------------------------------
## Links
--------------------------------------------------------------*/

a { text-decoration: none; color: #000; }
a:visited { }
a:hover { }
a:focus, a:hover, a:active { outline: none; }

/*--------------------------------------------------------------
## Typography
--------------------------------------------------------------*/

abbr[title] { border-bottom: 1px dotted; }
b, strong { font-weight: bold; }
blockquote { margin: 0; }
dfn { font-style: italic; }
hr { display: block; height: 1px; border: 0; border-top: 1px solid #ccc; margin: 1em 0; padding: 0; }
ins { background: #ff9; color: #000; text-decoration: none; }
mark {  }
pre, code, kbd, samp { font-family: monospace, serif; _font-family: 'courier new', monospace; font-size: 1em; }
pre { white-space: pre; white-space: pre-wrap; word-wrap: break-word; }
q { quotes: none; }
q::before, q::after { content: ""; content: none; }
small { font-size: 85%; }
sub, sup { font-size: 75%; line-height: 0; position: relative; vertical-align: baseline; }
sup { top: -0.5em; }
sub { bottom: -0.25em; }

/*--------------------------------------------------------------
## Lists
--------------------------------------------------------------*/

nav ul,
nav ol,
ul,
li { list-style: none; list-style-image: none; margin: 0; padding: 0; }

/*--------------------------------------------------------------
## Embedded Content
--------------------------------------------------------------*/

img { border: 0; -ms-interpolation-mode: bicubic; vertical-align: middle; }
svg:not(:root) { overflow: hidden; }

/*--------------------------------------------------------------
## Forms
--------------------------------------------------------------*/

figure { margin: 0; }
form { margin: 0; }
fieldset { border: 0; margin: 0; padding: 0; }
label { cursor: pointer; }
legend { border: 0; *margin-left: -7px; padding: 0; white-space: normal; }

button, input, select, textarea { font-size: 100%; margin: 0; vertical-align: baseline; *vertical-align: middle; }
button, input { line-height: normal; }
button, input[type="button"], input[type="reset"], input[type="submit"] { cursor: pointer; -webkit-appearance: button; *overflow: visible; }
button[disabled], input[disabled] { cursor: default; }
input, textarea {-webkit-box-shadow: none;}

input[type="checkbox"], input[type="radio"] { box-sizing: border-box; padding: 0; *width: 13px; *height: 13px; }
input[type="search"] { -webkit-appearance: textfield; -moz-box-sizing: content-box; -webkit-box-sizing: content-box; box-sizing: content-box; }
input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button { -webkit-appearance: none; }

button::-moz-focus-inner, input::-moz-focus-inner { border: 0; padding: 0; }

textarea { overflow: auto; vertical-align: top; resize: vertical; }

input:valid, textarea:valid {  }
input:invalid, textarea:invalid { background-color: #f0dddd; }

input, textarea, select {outline: none;}

/*--------------------------------------------------------------
## Tables
--------------------------------------------------------------*/

table { border-collapse: collapse; border-spacing: 0; }
td { vertical-align: top; }

/*--------------------------------------------------------------
## Chrome Frame Prompt
--------------------------------------------------------------*/

.chromeframe { margin: 0.2em 0; background: #ccc; color: black; padding: 0.2em 0; }
