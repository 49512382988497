.awards {
    padding: 110px 0 170px;
	
    @include mq($tablet-l) {
        padding: 78px 0 70px;
    }
	
    @include mq($phone) {
        padding: 50px 0;
    }
}

.awards__container {
    display: flex;
    justify-content: center;
    margin-top: 36px;

    @include mq($phone) {
        padding: 0 35px;
    }
}

.award-item {
    margin: 0 15px;
    flex: 0 0 20%;
    max-width: 20%;
	
    @include mq($phone) {
        img {
            width: 100px;
        }
    }
}