.header {
    padding: 20px 0;
    position: fixed;
    width: 100%;
    z-index: 10;
    background-color: $ghost-white;
    transition: $dur $ease;

    @include mq($phone) {
        padding: 9px 0;
    }
}

.header-fixed .header__logo img {
    width: 180px;
    transition: $dur $ease;
	
    @include mq($phone) {
        width: 100px;
    }
}

.header-fixed .main-nav {
    a {
        font-size: 16px;
    }
}

.header-fixed {
    padding: 0;
    box-shadow: 0 0 10px 0 rgba($black, 0.2);
}

.header-fixed .header__actions {
    a {
        padding: 19px 20px;
        font-size: 14px;
        line-height: 0;
    }

    .menu-btn {
        width: 30px;
        height: 19px;
        margin-left: 15px;
    }
    
}

.header__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    @include mq($tablet-l) {
        display: block;
    }
}


.header__wrap {
    @include mq($tablet-l) {
		display: none;
    }
}

.header__box {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mq($tablet-l) {
        height: 100vh;
        text-align: center;
        margin-top: 92px;
        display: block;
    }
}


.main-nav {
    li {
        display: inline-block;
        padding: 30px 24px 30px 0;
    }

    a {
        display: block;
        font-size: 18px;
        line-height: 1.16;
        color: $blue-whale;
        font-weight: 500;
        transition: $dur $ease;

        &:hover { 
            color: $brink-pink;
        }
    }

    @include mq($tablet-l) {
        display: block;

        > ul > li {
            display: block;
            padding: 30px 15px;
        }

        > ul > li > a {
            font-size: 32px;
        }
    }
	
	@include mq($phone) {
		 > ul > li {
            padding: 20px 15px;
        }
		
        > ul > li > a {
            font-size: 20px;
        }
    }
}

.header__actions {
    display: flex;
    align-items: center;

    @include mq($tablet-l) {
        margin-top: 20px;
        justify-content: center;
    }
}

.header__logo {
	display: inline-block;
    img {
        transition: $dur $ease;
        width: 270px;
    }
	
    @include mq($tablet-l) {
        img {
            width: 200px;
        }
    }
	
    @include mq($phone) {
        img {
            width: 142px;
        }
    }
}