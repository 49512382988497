.about {
    padding: 80px 0;
    position: relative;

    @include mq($tablet-l) {
        padding: 0 0 30px;
    }

    @include mq($phone) {
        padding: 192px 0 60px;
    }

    @include mq($phone-s) {
        padding: 50px 0 100px;
    }
}

.about__body {
    position: relative;
	
	@include mq($phone) {
        z-index: 1;
    }
}

.about__container {
    padding: 100px 0;
    position: relative;
	backface-visibility: hidden;

    @include mq($tablet-l) {
        padding: 100px 0;
    }

    @include mq($phone) {
        padding: 0 0 50px;
        display: grid;
    }
}


.about__blob {
	position: absolute;
	top: 8%;
	max-width: 41%;
	z-index: 1;
	
	.about__blobs--right & {
		right: 0;
    }

    @include mq($lap-large) {
        top: 16%;
    }
    
    @include mq($tablet-l) {
        top: 10%;
    }

    @include mq($phone) {
        top: 62%;
    }
}

.about__blob2 {
    top: 0;

    @include mq($tablet-l) {
        top: 17%;
    }

    @include mq($phone) {
        top: 44%;
        max-width: 41%;
    }

    @include mq($phone-s) {
        top: 49%;
        max-width: 36%;
    }
}

.about__blob--grey {
	max-width: 40%;
    top: 10%;
    
    @include mq($tablet-l) {
        max-width: 50%;
        top: -4%;
    }

    @include mq($phone) {
        top: 50%;
    }

    @include mq($phone-s) {
        top: 62%;
    }
}

.about__blob--grey2 {
    top: 0;

    @include mq($tablet-l) {
        width: 50%;
        top: 5%;
    }

    @include mq($phone) {
        width: 43%;
        top: 46%;
    }

    @include mq($phone-s) {
        width: 45%;
        top: 43%;
    }
}

.about__images {
    position: absolute;
    width: 50%;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    z-index: 1;

    @include mq($phone) {
        position: relative;
        width: 100%;
        top: 0;
        transform: translateY(0);
        order: 1;
    }

    @include mq($phone-s) {
       transform: translateY(15%);
    }
}

.about__wrap-box {
    @include mq($phone) {
        position: relative;
        width: 100%;
        top: 0;
        transform: translateY(0);
        margin-bottom: 5px;
        order: 0;
    }
}

.about__image {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);

    img {
        transition: $dur $ease;
    }

    &.active img {
        transform: translateY(-10px);
    }
	
	@include mq($phone) {
		top: 0;
		transform: translateY(0);
    }
}

.about__image-main {
	@include mq($phone) {
        position: relative;
    }
}

.about__image-sm {
    z-index: 1;
}

.about__head {
	max-width: 362px;
	text-align: right;
	margin-bottom: 60px;
	
	@include mq($phone) {
        max-width: 100%;
        margin-bottom: 20px;
        text-align: center;
    }
}

.about__info {
    z-index: -1;
    @include mq($phone) {
        margin-bottom: 0;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
}

.about__item {
    border-radius: 100px 0 0 100px;
    margin-bottom: 50px;
    padding: 20px;
    transition: $dur $ease;
    cursor: pointer;

    &:last-of-type {
        margin-bottom: 0;
    }

    &:first-of-type .about__item-inner {
        &::after {
            background-color: $mariner;
        }
    }

    &:last-of-type .about__item-inner {
        &::after {
            background-color: $brink-pink;
        }
    }

    &:nth-of-type(2) .about__item-inner {
        &::after {
            background-color: $shamrock;
        }
    }
    
    &.active {
        background-color: $ghost-white;
        .about__item-inner {
            transform: translateX(-20px);
        }
    }

    @include mq($tablet-l) {
        margin-bottom: 25px;
    }

    @include mq($phone) {
        border-radius: 0;
        margin-bottom: 6px;
        padding: 6px;
        flex: 0 0 33%;
        max-width: 33%;
        &.active {
            .about__item-inner {
                transform: translateX(0);
            }
        }
    }
}

.about__item-inner {
    max-width: 283px;
    width: 100%;
    position: relative;
    padding: 15px 20px;
    transition: $dur $ease;
    text-align: right;
    margin-left: 50px;

    &::after {
        content: '';
        top: 0;
        right: 0;
        position: absolute;
        height: 100%;
        width: 3px;
        background-color: $black;
    }

    @include mq($phone) {
        padding: 10px 0;
        margin-left: auto;
        
        &::before {
           content: '';
           position: absolute;
           top: 0;
           left: 50%;
           transform: translateX(-50%);
           width: 50%;
           height: 2px;
           background-color: $black;
        }

        &::after {
            display: none;
        }
    }
}

.about__item-content {
    font-size: 24px;
    line-height: 1.16;
    font-weight: 800;
    transition: $dur $ease;
    color: $blue-whale;

    @include mq($tablet-l) {
        font-size: 19px;
        max-width: 224px;
        width: 100%;
        margin: 0 auto;
    }

    @include mq($phone) {
        font-size: 12px;
        text-align: center;
        max-width: 190px;
    }

    @include mq($phone-s) {
        font-size: 11px;
    }
}

.about__btn {
    max-width: 360px;
	text-align: right;
	z-index: 1;
	margin-top: 70px;
	
	@include mq($phone) {
        max-width: 100%;
        margin-top: 0;
        text-align: center;
    }
}