.footer {
    padding: 200px 0 71px;
    background-image: url('assets/images/footer-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-color: $ghost;
    position: relative;

    @include mq($tablet-l) {
        position: relative;
    }

    @include mq($phone) {
        padding: 120px 0 40px;
    }
}

.footer__image {
    position: absolute;
    top: -40%;
    right: 11%;
    width: 25%;

    @include mq($lap-large) {
        top: -20%;
    }

    @include mq($lap-small) {
        top: -18%;
    }

    @include mq($tablet-l) {
        display: none;
    }
}

.footer__item {
    @include mq($phone) {
        margin-bottom: 50px;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.footer__container {
    display: flex;
    justify-content: space-between;
    max-width: 840px;

    @include mq($phone) {
        display: block;
    }
}

.footer__item-logo {
    img {
        transform: translateY(-19px);
    }

    @include mq($tablet-l) {
        img {
            transform: none;
            max-width: 220px;
        }
    }

    @include mq($phone) {
        img {
            max-width: 150px;
        }
    }
}

.footer__item-title {
    color: $white;
    font-size: 24px;
    line-height: 1.16;
    font-weight: 700;
    margin-bottom: 35px;

    @include mq($phone) {
        font-size: 19px;
        margin-bottom: 20px;
    }
}

.footer__item-list {
    a {
        color: $mischka;
        font-size: 18px;
        line-height: 1.16;
        display: block;
        margin-bottom: 20px;

        &:hover {
            color: $white;
        }

        @include mq($tablet-l) {
            a {
                font-size: 15px;
            }
        }
    }
}

.footer__item-logo-info {
    span {
        display: block;
        font-size: 20px;
        line-height: 1.16;
        font-weight: 800;
        color: $white;
        margin: 80px 0 30px;
    }
}

.footer__item-media {
    display: flex;
    margin-bottom: 90px;

    .icon {
        color: $mischka;
        transition: $dur $ease;
        &:hover {
            color: $white;
        }
    }

    a {
        margin-right: 13px;

        &:last-of-type {
            margin-right: 0;
        }

        &:hover {
            color: $white;
        }
    }

    @include mq($tablet-l) {
        margin-bottom: 46px;
    }
}

.copyright {
    font-size: 18px;
    line-height: 1.35;
    color: $mischka;
    display: block;
    margin-bottom: 5px;
    
    &:last-of-type {
        margin-bottom: 0;
    }
}
