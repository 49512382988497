.features {
	padding: 50px 0;
	
    @include mq($phone) {
        padding: 20px 0;
    }	
}

.features__section {
    padding: 80px 0 0;
	position: relative;
	
	@include mq($tablet-l) {
        padding: 137px 0 150px;
    }
	
    @include mq($phone) {
        padding: 142px 0 112px;
	}
	
	@include mq($phone-s) {
        padding: 36px 0 69px;
    }
}

.features__section--xx {
	@include mq($phone) {
        padding: 100px 0 25px;
    }
}

.features__wrap	{
	margin-top: 150px;
	@include mq($tablet-l) {
        margin-top: 0;
    }
}

.features__blob {
	position: absolute;
	top: -7%;
	max-width: 41%;
	
	.features__blobs--right & {
		right: 0;
	}

	.features__blobs--left & {
		left: 0;
	}

	@include mq($tablet-l) {
		max-width: 39%;
	}

	@include mq($phone) {
		top: 22%;
		max-width: 75%;
	}

	@include mq($phone-s) {
		top: 21%;
		max-width: 75%;
	}
}

.features__blob2 {
	top: 5%;

	@include mq($phone) {
		max-width: 60%;
		top: 25%;
	}
}

.features__blob3 {
	top: -10%;
	@include mq($phone) {
		max-width: 54%;
		top: 31%;
	}
	@include mq($phone-s) {
		top: 25%;
		max-width: 60%;
	}
}

.features__blob4 {
	top: 5%;

	@include mq($tablet-l) {
		max-width: 38%;
		top: -13%;
	}

	@include mq($phone) {
		max-width: 62%;
		top: 21%;
	}

	@include mq($phone-s) {
		max-width: 52%;
		top: 39%;
	}
}

.features__blob--grey {
	max-width: 56%;
	top: -11%;

	@include mq($tablet-l) {
		top: -2%;
	}

	@include mq($phone) {
		max-width: 92%;
		top: 11%;
	}

	@include mq($phone-s) {
		top: 26%;
	}
}

.features__blob--grey2 {
	top: 0;

	@include mq($phone) {
		top: 26%;
	}
}

.features__blob--grey3 {
	top: -20%;

	@include mq($phone) {
		max-width: 93%;
		top: 22%;
	}
}

.features__blob--grey4 {
	top: -9%;

	@include mq($tablet-l) {
		max-width: 56%;
		top: -21%;
	}

	@include mq($phone) {
		max-width: 79%;
		top: 23%;
	}

	@include mq($phone-s) {
		top: 37%;
		width: 69px;
	}
}

.features__content {
	display: flex;
	align-items: center;
	position: relative;
	z-index: 1;
	margin-top: -135px;

	@include mq($tablet-l) {
        margin-top: -87px;
    }

	@include mq($phone) {
		margin-top: -35px;
		display: block;
    }
}

.features__content-xl {
	padding: 150px 0;

	@include mq($tablet-l) {
		padding: 0;
    }
}

.features__content--center {
	justify-content: center;
}

.features__content--left {
	justify-content: flex-start;
}

.features__content--left-margin {
	margin-left: 20px;

	@include mq($phone) {
		margin-left: 30px;
    }
}

.features__content--flip {
	.features__content-info {
		order: 1;
		text-align: left;
		
		&::after {
			right: auto;
			left: 15px;
		}
	}
	
	.features__images--2 {
		order: 0;
	}
}

.features__content-info {
	flex-shrink: 0;
	max-width: 553px;

	h3 {
		font-size: 36px;
		line-height: 1.2;
		text-align: right;
		font-weight: bold;
		color: $blue-whale;
		position: relative;
		padding: 35px 15px;

		&::after {
			content: '';
			position: absolute;
			bottom: 0;
			right: 15px;
			width: 361px;
			height: 3px;
			background-color: $brink-pink;
		}
	}

    @include mq($tablet-l) {
		max-width: 322px;
		
		h3 {
			font-size: 20px;
			padding: 18px 15px;

			&::after {
				width: 151px;
			}
		}
    }
	
	@include mq($phone) {
		max-width: 262px;
		margin: 0 auto;
		
		h3 {
			font-size: 16px;
			padding: 20px 15px;
			text-align: center;
			
			&::after {
				width: 127px;
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}
}

.features__content-info--left {
	max-width: 665px;

	h3 {
		text-align: left;

		&::after {
			left: 19px;
		}
	}

	@include mq($tablet-l) {
		max-width: 404px;
	}

	@include mq($phone) {
		h3 {
			font-size: 16px;
			text-align: center;

			&::after {
				width: 127px;
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}
}

.features__content-info-xl {
	max-width: 701px;
}

.features__content-info-m {
	max-width: 668px;

	@include mq($tablet-l) {
		max-width: 393px;
	}
}

.features__content-info-l {
	max-width: 600px;

	@include mq($tablet-l) {
		max-width: 350px;
	}
}

.features__content-info--s {
	max-width: 550px;
}

.features__images {
	margin-left: 120px;
	
	.features__content--flip & {
		margin-right: 120px;
		margin-left: 0;
	}
	
	@include mq($tablet-l) {
		margin-left: 60px;
		
		.features__content--flip & {
			margin-right: 60px;
			margin-left: 0;
		}
	}

	@include mq($phone) {
		margin: 60px auto;
		
		.features__content--flip & {
			margin-right: 20px;
			margin-left: 0;
		}
	}
}

.features__img {
	position: relative;
	overflow: hidden;
	z-index: 1;
}

.features__img-animate {
	position: absolute;
	top: 3%;
	right: 8%;
	z-index: -1;
	max-width: 85%;
}

.features__img-tablet-animate {
	position: absolute;
	top: 4%;
	z-index: -1;
	width: 83.2%;
	left: 9%;

	img {
		width: 100%;
	}
}

.features__img-headset-animate {
	position: absolute;
	top: 3%;
	right: 2.2%;
	width: 31.3%;
	z-index: -1;
}

.features__images--1 {
	max-width: 19%;

	@include mq($phone) {
		max-width: 54%;
	}
}

.features__images--2 {
	max-width: 45%;

	@include mq($phone) {
		max-width: 84%;
	}
}

.features__images--3 {
	max-width: 49%;

	@include mq($phone) {
		max-width: 95%;
	}
}

.features__images--4 {
	max-width: 46%;
	
	@include mq($phone) {
		max-width: 91%;
	}
}