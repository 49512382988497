.preloader {
    height: 100%;
    width: 100%;
    background: $white;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
	transition: $dur $ease;
	visibility: visible;
	opacity: 1;

    &.loaded {
		opacity: 0;
        visibility: hidden;
    }
}

.preloader__img {
	width: 70px;
	height: 58px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) scale(0.85);
	animation: fade 1400ms linear infinite;
	transform-origin: center;
	opacity: 0;
}

@keyframes fade {
	70% { 
		opacity: 1;
		transform: translate(-50%, -50%) scale(1);
	} 
}

// .preloader__section {
//     position: fixed;
//     top: 0;
//     width: 100%;
//     height: 100%;
//     background: $white;
//     z-index: 1000;
//     transform: translateX(0);
// }

// .preloader__section--left {
//     left: -50%;

//     .loaded & {
//         transform: translateX(-100%);
//         transition: 1300ms cubic-bezier(0.645, 0.045, 0.355, 1);
//     }
// }

// .preloader__section--right {
//     right: -50%;

//     .loaded & {
//         transform: translateX(100%);
//         transition: 1300ms cubic-bezier(0.645, 0.045, 0.355, 1);
//     }
// }