.banner {
    background-image: url('assets/images/blob.png');
    background-position: bottom;
    padding: 162px 0 200px;
    background-size: cover;

    @include mq($lap-small) {
        padding: 142px 0 200px;
    }

    @include mq($tablet-l) {
        padding: 142px 0 110px;
    }
    
    @include mq($phone) {
        padding: 77px 0;
    }
}

.banner--xl {
	padding: 309px 0 340px;
	
	@include mq($tablet-l) {
        padding: 142px 0 129px;
    }

    @include mq($phone) {
        padding: 83px 0 61px;
    }
}

// .banner__image {
// 	img {
// 		max-height: 69vh;
// 		margin: 0 auto;
// 	}
	
//     @include mq($phone) {
//         margin-top: 40px;
//     }
// }

.banner__video-container {
    text-align: center;
    line-height: 0;
}

.banner__video1 {
    height: auto;

    @include mq($tablet-l) {
        width: 66%;
    }

    @include mq($phone) {
        width: 100%;
    }
}

.banner__video2 {
    height: auto;

    @include mq($tablet-l) {
        display: none;
    }
}

.banner__image-desc {
	position: relative;
	display: flex;
	align-items: center;
	max-width: 83%;
    margin: 36px auto;

    @include mq($lap-small) {
        bottom: 18px auto;
     }

    @include mq($tablet-l) {
        display: none;
    }
}

.banner__image-desc-left,
.banner__image-desc-right {
	flex: 0 0 50%;
	max-width: 50%;
    text-align: center;
    
    span {
        font-size: 24px;
        line-height: 1.2;
        color: $blue-whale;
        display: block;
        font-weight: bold;
    }

    @include mq($phone) {
        span {
            font-size: 14px;
        }
    }
}

.banner__title {
    font-size: 59px;
    line-height: 1.16;
    text-align: center;
    font-weight: 800;
    margin-bottom: 10px;
    font-family: $font-main;
    color: $blue-whale;
	
    @include mq($lap-large) {
        font-size: 50px;
    }
	
    @include mq($tablet-l) {
        font-size: 40px;
    }
	
    @include mq($phone) {
        font-size: 30px;
    }
}

.banner__title--xl {
    margin-bottom: 50px;
    @include mq($phone) {
        margin-bottom: 25px;
    }	
}

.banner__subtitle {
    font-size: 29px;
    line-height: 1.2;
    text-align: center;
    color: $blue-whale;
    margin-bottom: 0;
	
    @include mq($lap-large) {
        font-size: 28px;
    }
	
    @include mq($tablet-l) {
        font-size: 20px;
    }
	
    @include mq($phone) {
        font-size: 15px;
        line-height: 1.5;
    }
}

.banner__subtitle--xl {
	max-width: 1256px;
	margin: 0 auto 30px;	
}

.banner__subtitle--l {
    max-width: 995px;
    margin: 0 auto 30px;
}

.banner__subtilte--md {
	max-width: 910px;
	margin: 0 auto;
}

.banner__subtitle--m {
    max-width: 741px;
    margin: 0 auto;
}

.banner__arrow {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    z-index: 10;
    cursor: pointer;
    transition: $dur $ease;
    bottom: -40px;

    .icon {
        color: $keppel;
        transition: $dur $ease;

        &:hover {
            color: $brink-pink;
        }
    }

    @include mq($lap-large) {
        bottom: -29px;
    }

    @include mq($lap-small) {
        bottom: -12px;
    }
 
}

@keyframes drop {
    0% {
        top: 5px;
        opacity: 0;
    }

    30% {
        top: 10px;
        opacity: 1;
    }

    100% {
        top: 25px;
        opacity: 0;
    }
}