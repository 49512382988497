.job-desc {
    padding: 70px 0;

    @include mq($phone) {
        padding: 40px 0 0;
	}
}

.job-desc__wrap {
    margin-bottom: 50px;
}

.job-desc__title {
    font-size: 18px;
    line-height: 1.2;
    color: $blue-whale;
    margin-bottom: 30px;
    font-weight: bold;
}

.job-desc__btn {
    text-align: center;
    margin-top: 70px;

    @include mq($phone) {
		margin-top: 0;
    }
}