/*--------------------------------------------------------------
## Base Layout
--------------------------------------------------------------*/
html, body {
	@include font-smoothing(antialiased);
	height: 100%;
}

html {
	box-sizing: border-box;
}

body {
	&.noscroll {
		overflow: hidden;
	}
}