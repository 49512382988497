/*--------------------------------------------------------------
## Forms
--------------------------------------------------------------*/

input[type='text'],
input[type='email'],
input[type='tel'] {
	width: 100%;
    padding: 15px;
	border-radius: 5px;
    outline: none;
    border: 2px solid $solitude;
    margin: 0 auto;
    
	@include mq($phone) {
        padding: 7px;
	}
}

textarea {
    height: 226px;
    width: 100%;
    border-radius: 6px;
    padding: 23px 32px;
    border: 2px solid $solitude;
}

label,
.label {
	font-size: 18px;
	font-weight: bold;
	display: block;
	margin-bottom: 10px;
	text-align: left;

	@include mq($phone) {
        font-size: 15px;
	}
}

.file-input {
	height: 235px;
	width: 100%;
    border-radius: 6px;
    border: 1px solid $solitude;
	display: flex;
	align-items: center;
	justify-content: center;
	
	input {
		width: 0.1px;
		height: 0.1px;
		opacity: 0;
		overflow: hidden;
		position: absolute;
		z-index: -1;
	}
	
	label {
		cursor: pointer;
		display: inline-block;
		font-size: 18px;
		font-weight: normal;
		margin: 0;
		color: $mariner;
		margin-right: 7px;

		@include mq($phone) {
			font-size: 15px;
		}
	}
	
	span {
		font-size: 18px;
		display: inline-block;

		@include mq($phone) {
			font-size: 15px;
		}
	}
}