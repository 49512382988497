.team {
	padding: 65px 0;
	
	@include mq($tablet-l) {
		padding-top: 50px;
    }

	@include mq($phone) {
		padding: 25px 0 0;
    }
}

.team__container-first {
	display: flex;
	justify-content: center;
	margin-top: 55px;
}

.team__item-first {
	flex: 0 0 50%;
	max-width: 50%;
	border-radius: 50%;
	
	img {
		width: 143px;
		height: 143px;
		margin: 0 auto 40px;
		transition: $dur $ease;
		transform: scale(1);
		
		&:hover {
			transform: scale(1.1);
			cursor: pointer;
		}
	}
	
	@include mq($phone) {
		img {
			width: 100px;
			height: 100px;
			margin: 0 auto 10px;
		}
    }
}

.team__container-second {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-top: 140px;

	@include mq($tablet-l) {
		margin-top: 50px;
    }

	@include mq($phone) {
		margin-top: 34px;
    }
}

.team__item-second {
	flex: 0 0 25%;
	max-width: 25%;
	margin-bottom: 200px;
	
	&:last-of-type {
		margin-bottom: 80px;
	}
	
	img {
		width: 143px;
		height: 143px;
		margin: 0 auto 40px;
		transition: $dur $ease;
		transform: scale(1);
		
		&:hover {
			transform: scale(1.1);
			cursor: pointer;
		}
	}
	
	@include mq($tablet-l) {
        flex: 0 0 50%;
		max-width: 50%;
		margin-bottom: 50px;
    }
	
	@include mq($phone) {
		margin-bottom: 34px;
		
		&:last-of-type {
			margin-bottom: 20px;
		}

		&:nth-last-of-type(2) {
			margin-bottom: 20px;
		}
		
		img {
			width: 100px;
			height: 100px;
			margin: 0 auto 10px;
		}
    }
}

.team__item-second-info {
	text-align: center;
}

.team__item-second-info-name {
	font-size: 27px;
	line-height: 1.2;
	font-weight: bold;
	margin-bottom: 6px;
	color: $blue-whale;
	
	@include mq($tablet-l) {
        font-size: 20px;
    }
	
	@include mq($phone) {
		font-size: 14px;
    }
}

.team__item-second-info-position {
	display: block;
	font-size: 25px;
	line-height: 1.4;
	color: $blue-whale;
	
	@include mq($tablet-l) {
        font-size: 18px;
    }
	
	@include mq($phone) {
		font-size: 13px;
    }
}