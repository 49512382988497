.entry-content {
    p {
        font-size: 16px;
        line-height: 1.37;
        color: $blue-whale;
        margin-bottom: 30px;
    }

    ul {
        li {
            list-style-type: disc;
            list-style-position: inside;
			
			ul {
				padding: 0 15px;
			}
        }
    }

    @include mq($phone) {
		p {
            font-size: 15px;
        }
    }
}