/*--------------------------------------------------------------
## Animations
--------------------------------------------------------------*/

@include mq($phone + 1, min) {
    .is-animate {
        backface-visibility: hidden;
        opacity: 0;
        will-change: transform;
    }
	
	
	// animations:
	.fade-in {
        transition: 800ms ease-in-out;
    }

    .slide-up {
        transform: translate3d(0, 200px, 0);
        transition: 1000ms ease-in-out;
    }

    .slide-left-xl {
        transform: translateX(-450px);
        transition: 1000ms ease-in-out;
    }


	// visible
    .is-visible {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
	
}