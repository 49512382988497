/*--------------------------------------------------------------
## Variables
--------------------------------------------------------------*/
// Fonts
$font-main: 'Gilroy';

$white: #fff;
$black: #000;
$dark: #25282a;
$grey-dark: #4a4a4a;
$grey: #666;
$grey-light: #9b9b9b;
$light: #e8e8e8;
$link-water: #c7c9d3;
$brink-pink: #fb637e;
$red-dark: #852d3d;
$manatee: #969aa7;
$mischka: #afb2bd;
$shamrock: #47d7ac;
$mariner: #3b6cb3;
$blue-whale: #253746;
$quartz: #e1e1e2;
$gossamer: #319388;
$keppel: #54bd92;
$magnolia: #f5f4f6;
$ghost-white: #f6f6f7;
$ghost: #f3f3f4;
$solitude: #e6e7e9;
$very-light-grey: #cecece;

// Transitions
$dur: 350ms;
$ease: ease;

// Breakpoints
$phone-s: 551;
$phone: 767;
$tablet: 1024;
$tablet-l: 1199;
$lap-small: 1290;
$lap-large: 1500;
