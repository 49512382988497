.careers-m {
    padding: 119px 0 19px;

    @include mq($phone) {
        padding: 42px 0 19px;
	}
}

.careers-m__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    @include mq($tablet-l) {
        align-items: unset;
	}
}

.careers-m__item {
    flex: 0 0 33.3%;
    max-width: 33%;
    position: relative;
    padding-bottom: 35px;
    margin-bottom: 100px;

    a {
        font-size: 24px;
        line-height: 1.2;
        font-weight: bold;
        display: block;
        color: $blue-whale;
        transition: $dur $ease;
        // text-align: center;
    }

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 220px;
        height: 3px;
        background-color: $very-light-grey;
        transition: $dur $ease;
    }

    &:hover {
        a {
            color: $brink-pink;
        }

        &::after {
            background-color: $brink-pink;
        }
    }

    @include mq($tablet-l) {
        a {
            text-align: center;
        }

        &::after {
            left: 50%;
            transform: translate(-50%);
        }
    }

    @include mq($phone) {
        flex: 0 0 50%;
        max-width: 50%;
        margin-bottom: 30px;
        padding-bottom: 7px;

        a {
            font-size: 14px;
            max-width: 155px;
            width: 100%;
            margin: 0 auto;
        }

        &::after {
            width: 71%;
        }
    }
}