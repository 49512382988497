.contact__form {
	background-color: $white;
	box-shadow: 0 2px 50px rgba(76, 76, 76, 0.2);
	transform: translateY(-20%);
	padding: 45px 0;
	border-radius: 60px;

	@include mq($tablet-l) {
        transform: translateY(-10%);
	}
	
	@include mq($phone) {
		transform: translateY(0);
		box-shadow: none;
		padding: 35px 0 0;
    }
}

.contact__form-container {
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto;
	padding: 0 25px;

	@include mq($phone) {
        display: block;
    }
}

.contact__wrap {
	flex: 0 0 50%;
	max-width: 50%;
	padding: 20px 50px 0;
	flex-direction: column;

	@include mq($phone) {
	   flex: 0 0 100%;
	   max-width: 100%;
	   padding: 0;
	   margin-bottom: 15px;
    }
}

.contact__wrap--xl {
	flex: 0 0 100%;
	max-width: 100%;
	padding: 20px 50px 0;

	@include mq($phone) {
		padding: 0;
	}
}

.contact__wrap-message {
	padding: 20px 50px;
	width: 100%;

	@include mq($phone) {
		padding: 0;
	}
}

.contact__btn {
	text-align: center;
	margin-top: 20px;
}