.section-head {
    @include mq($tablet-l) {
        padding: 0 25px;
    }

    @include mq($phone) {
        padding: 0;
    }
}

.section-head--center {
    text-align: center;
}

.section-head__title {
    font-size: 42px;
    line-height: 1.16;
    font-family: $font-main;
    font-weight: 800;
    margin-bottom: 20px;
    color: $blue-whale;
	
    @include mq($tablet-l) {
        font-size: 30px;
    }
	
    @include mq($phone) {
        font-size: 21px;
    }
}

.section-head__title--right {
    @include mq($tablet-l) {
        font-size: 23px;
    }

    @include mq($phone) {
        text-align: center;
    }
}

.section-head__title--sm {
    max-width: 834px;
    width: 100%;
    margin: 0 auto 70px;

    @include mq($phone) {
        font-size: 18px;
        margin: 0 auto 40px;
        max-width: 641px;
    }
}

.section-head__subtitle {
    font-size: 18px;
    line-height: 1.4;
    font-family: $font-main;
    color: $mischka;
    max-width: 690px;
    width: 100%;
	
    @include mq($tablet-l) {
        font-size: 17px;
        max-width: 648px;
    }
	
    @include mq($phone) {
        font-size: 14px;
		line-height: 1.4;
    }
}

.section-head__subtitle--center {
    margin: 0 auto;
}

.section-head__subtitle--md {
    max-width: 455px;
}

.section-head__subtitle--xs {
    max-width: 448px;
}

.section-head__subtitle--xxs {
    max-width: 262px;

    @include mq($phone) {
       text-align: center;
       margin: 0 auto;
    }
}