/*--------------------------------------------------------------
## Layout
--------------------------------------------------------------*/

.wrapper {
    width: 100%;
    max-width: 1800px;
    margin: 0 auto;
    padding: 0 15px;
}

.wrapper--xl {
	max-width: 1600px;
	
	@include mq($tablet-l) {
       max-width: 800px;
    }
}

.wrapper--md {
    max-width: 1520px;
}

.wrapper--mmd {
    max-width: 1415px;
}

.wrapper--mmmd {
    max-width: 1260px;
}

.wrapper--4m {
    max-width: 1200px;
}

.wrapper--sm {
    max-width: 945px;
}

.wrapper--xs {
    max-width: 1021px;
}

.wrapper--xxs {
    max-width: 975px;
}

.wrapper--xxxss {
    max-width: 800px;
}

.wrapper--xxxs {
    max-width: 720px;
}