.quotes {
    position: relative;
    padding: 150px 0;
	
    @include mq($tablet-l) {
        padding: 100px 0;
    }
	
	@include mq($phone) {
		padding: 15px 0;
	}
}

.quotes__blobs {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    width: 51%;
	
	@include mq($phone) {
		display: none;
	}
}

.quotes__blobs--grey {
	position: absolute;
	bottom: -8%;
	right: 16%;
	z-index: -1;
	width: 100%;
}

.quote__section-head-title {
	max-width: 319px;
	
	@include mq($phone) {
		text-align: center;
		max-width: 100%;
	}
}

.quotes__container {
    margin-top: 64px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
	
	@include mq($phone) {
		display: block;
		margin-top: 22px;
	}
}

.quotes__items {
	flex-shrink: 0;
	
	@include mq($phone) {
		margin-bottom: 30px;
	}
}

.quotes__images {
    border-bottom: 5px solid $shamrock;
    margin: 0 50px;
	z-index: 1;
	position: relative;
	overflow: hidden;
	
	@include mq($phone) {
		max-width: 190px;
    	margin: 0 auto 30px;
	}
}

.quotes__image {
	position: absolute;
	bottom: 0;
	opacity: 0;
	transition: 400ms $ease;
	
	&:first-of-type {
		position: relative;
	}
	
	&.active {
		opacity: 1;
		transition: 400ms 200ms $ease;
	}
}

.quote__item {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 15px;
	position: relative;
	padding: 20px;
	cursor: pointer;

    &:last-of-type {
        margin-bottom: 0;
    }
	
	&::before {
		content: '';
		background-color: $ghost-white;
		position: absolute;
		width: 300%;
		height: 100%;
		left: 0;
		border-radius: 50px;
		opacity: 0;
		transition: $dur $ease;
	}
	
	&.active {
		&::before {
			opacity: 1;
		}
	}
	
	@include mq($phone) {
		flex-direction: row-reverse;
		padding: 11px;
		
		&::before {
			width: 100%;
		}
	}
}

.quote__item-info {
    position: relative;
    padding-right: 15px;
    text-align: right;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 3px;
        background-color: $brink-pink;
        opacity: 0.3;
		transition: $dur $ease;
    }
	
	span {
        display: block;
        font-size: 20px;
        line-height: 1.16;
        font-weight: 800;
        color: $blue-whale;
        opacity: 0.3;
		transition: $dur $ease;
    }
	
    @include mq($tablet-l) {
        span {
            font-size: 15px;
        }
    }
	
	.active & {
		&::before {
			opacity: 1;
		}
		
		span {
			opacity: 1;
		}
	}
	
	@include mq($phone) {
		padding-right: 0;
		padding-left: 15px;
		text-align: left;
		margin-left: 10px;

		&::before {
			right: auto;
			left: 0;
		}
    }
}

.quote__item-info-icon {
    img {
        padding-left: 15px;
        opacity: 0.6;
        height: 39px;
		transition: $dur $ease;
		position: relative;
    }
	
	.active & {
		img {
			opacity: 1;
		}
	}
}

.quotes__contents {
    max-width: 481px;
	z-index: 1;
	position: relative;

	@include mq($phone) {
		text-align: center;
		margin: 0 auto;
    }
}

.quotes__content {
	position: absolute;
	top: 0;
	opacity: 0;
	transition: 400ms $ease;
	transform: translateY(20px);
	
	&::before {
		content: font-char(quotes);
		@include font-styles;
		line-height: 1;
		position: absolute;
		top: 0;
		left: 0;
		color: $dark;
		font-size: 70px;
		transform: translate(-18px, -30px);
		opacity: 0.2;
	}
	
	&:first-of-type {
		position: relative;
	}
	
	&.active {
		opacity: 1;
		transform: translateY(0);
		transition: 400ms 200ms $ease;
	}
}

.quotes__content-text {
    font-size: 24px;
    line-height: 1.45;
    font-family: $font-main;
    color: $white;
	position: relative;
	font-weight: 600;
	
    @include mq($tablet-l) {
        font-size: 18px;
    }
	
	@include mq($tablet) {
        font-size: 14px;
    }
	
	@include mq($phone) {
		color: $blue-whale;
	}
}